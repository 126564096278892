<template>
    <v-container fluid pa-2>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col xs-12>
                    <v-tabs color="light" grow>
                        <v-tabs-slider color="blue"></v-tabs-slider>
                        <v-tab href="#tab">
                            Events
                        </v-tab>
                        <v-tab-item :value="'tab'">
                            <v-col cols="12">

                                    <v-card
                                        v-for="(item,n) in filteredEvents"
                                        :key="`card-${n}`"
                                        dark rounded
                                    >
                                        <v-card-text>
                                        <simple-event
                                        :event="item" 
                                        item-key="id"
                                        v-bind:index="n"
                                        />
                                        </v-card-text>
                                    </v-card>
                                  

                            </v-col>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters} from 'vuex'
//import Event from '@/components/event/Event'
//import EventHeader from '@/components/event/EventHeader'
import api from '@/store/api'
import moment from 'moment-timezone'
import SimpleEvent from './event/SimpleEvent.vue'

moment.updateLocale('en', {
    calendar : {
        lastDay : '[Yesterday at] LT',
        sameDay : '[Today] LT',
        nextDay : 'ddd LT',
        lastWeek : '[last] dddd [at] LT',
        nextWeek : 'ddd LT',
        sameElse : 'l'
    },
    longDateFormat : {
        LT : 'ha',
        LTS: "h:mm:ss A",
        L: "MM/DD/YYYY",
        l: "M/D/YYYY",
        LL: "MMMM Do YYYY",
        ll: "MMM D YYYY",
        LLL: "MMMM Do YYYY LT",
        lll: "MMM D YYYY LT",
        LLLL: "dddd, MMMM Do YYYY LT",
        llll: "ddd, MMM D YYYY LT"
    },
});

export default {
    components: {
      //  Event,
     //   EventHeader
        SimpleEvent
    },
    mounted () {
        this.$store.dispatch("bindEvents")
    },
    computed: {
        ...mapGetters ([
            'loading',
            'userInfo',
            'events'
        ]),
        filteredEvents() {
            return this.events.filter(event => this.$store.getters['isAdmin'] || 
                (event.status == "Approved" && true))
        }
    },
    methods: {
        refresh () {
        //    api.getAvailableEvents()
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        lastDay (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff < 24
        },
    }
}
</script>